const isFridayOrSaturday = (value) => {
  const date = new Date(value);
  const day = date.getDay();
  return day === 5 || day === 6 ? true : false;
};

const nthNumber = (number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const getFormattedDateForDisplay = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  return `${month} ${day}${nthNumber(day)}`;
};

const getEligibleOffers = (
  deliveryOption,
  date,
  totalAmount,
  occasion,
  selectedAddons = [], // Default to empty array if undefined
  menuDataObj = { specialOffers: {} }, // Default to empty object if undefined
  selectedMenu
) => {
  const dayOfWeek = date.getDay();
  const month = date.getMonth();

  console.log("selectedAddonsF ", selectedAddons);

  // Use default empty object if specialOffers is undefined or null
  const specialOffers = filterSpecialOffersBySelectedAddons(
    menuDataObj.specialOffers || {}, // Ensure it is an object
    selectedAddons || [] // Ensure it is an array
  );

  const isMatchingOccasion = (offerOccasions, selectedOccasion) =>
    offerOccasions.includes("all") || offerOccasions.includes(selectedOccasion);

  // Return an empty array if specialOffers is not an object
  return Object.entries(specialOffers)
    .filter(([_, offer]) => {
      console.log("key", _);
      const {
        isActive = false,
        occasions = [],
        deliveryOptions = [],
        offeredToMenus = [],
        offeredOnDays = [],
        offeredMonths = [],
        minimum = 0,
      } = offer;

      return (
        isActive &&
        isMatchingOccasion(occasions, occasion) &&
        deliveryOptions.includes(deliveryOption) &&
        offeredToMenus.includes(selectedMenu) &&
        offeredOnDays.includes(dayOfWeek) &&
        offeredMonths.includes(month) &&
        totalAmount >= minimum
      );
    })
    .map(([key]) => key);
};

const filterSpecialOffersBySelectedAddons = (
  specialOffers = {},
  selectedAddons = []
) => {
  // Convert selectedAddons to a flat array of selected item IDs
  const selectedAddonIds = selectedAddons.flatMap((addonGroup) =>
    Object.values(addonGroup).map((addon) => addon.itemId)
  );

  return Object.keys(specialOffers).reduce((filteredOffers, offerKey) => {
    const offer = specialOffers[offerKey];

    // Default empty array if dontShowIfItemsSelected is undefined
    const dontShowIfItemsSelected = offer.dontShowIfItemsSelected || [];

    // Check if any selected add-ons are in the dontShowIfItemsSelected array
    const shouldShowOffer = !dontShowIfItemsSelected.some((addonId) =>
      selectedAddonIds.includes(addonId)
    );

    // If the offer should be shown, add it to the filtered offers
    if (shouldShowOffer) {
      filteredOffers[offerKey] = offer;
    }

    return filteredOffers;
  }, {});
};

export {
  isFridayOrSaturday,
  nthNumber,
  getFormattedDateForDisplay,
  filterSpecialOffersBySelectedAddons,
  getEligibleOffers,
};
